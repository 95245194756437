<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="姓名：">
        <el-input size="small" placeholder="请输入姓名" v-model="searchForm.name"></el-input>
      </el-form-item>
      <el-form-item label="邮箱：">
        <el-input size="small" placeholder="请输入邮箱" v-model="searchForm.email"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="用户姓名" align="center"></el-table-column>
      <el-table-column prop="email" label="用户邮箱" align="center"></el-table-column>
      <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
      <el-table-column prop="message" label="留言信息" align="center"></el-table-column>
      <el-table-column label="更新时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="deleteData(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      page: 1,
      rows: 10,
      total: 0,
      searchForm: {
        name: '',
        email: '',
      },
      dataList: [],
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    clearSearch() {
      this.searchForm = {
        name: '',
        email: '',
      };
      this.getDataList(1);
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getDataList();
    },
    getDataList(style) {
      if (style) this.page = 1;
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.searchForm.name) obj.name = this.searchForm.name;
      if (this.searchForm.email) obj.email = this.searchForm.email;
      this.$axios.post(this.$api.PCOfficialWebsite.contactUsList, obj).then(res => {
        if (res.code === 0) {
          this.dataList = res.result.list;
          this.total = res.result.total_number;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    deleteData(data) {
      this.$confirm('确认删除此文章？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$axios
            .post(this.$api.PCOfficialWebsite.delContactUs, {
              id: data.id,
            })
            .then(res => {
              if (res.code == 0) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                });
                this.getDataList();
              } else {
                this.$message.error(res.msg);
              }
            });
        })
        .catch(err => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
}
</style>
